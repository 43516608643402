<div
  class="flex align-items-center justify-content-center"
  style="height: 80vh"
  *ngIf="loading"
>
  <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
</div>

<div class="grid" *ngIf="!loading">
  <div class="col-12">
    <div class="card p-fluid">
      <h2>Editar Empresa</h2>
      <hr />
      <form [formGroup]="newEmpresa" class="">
        <div class="field pl-0 pt-1 pb-0 md:col-12">
          <label class="w-full" for="nombre">Nombre</label>
          <input
            autocomplete="nope"
            id="nombre"
            type="text"
            pInputText
            formControlName="nombre"
            [ngClass]="{
              'ng-invalid ng-dirty': hadSubmit && !validar('nombre')
            }"
          />
          <small
            class="p-error"
            *ngIf="hadSubmit && newEmpresa.controls.nombre.invalid"
            >Campo requerido.</small
          >
        </div>
        <div class="field pl-0 pt-1 pb-0 md:col-12">
          <label class="w-full" for="NIF">NIF</label>
          <input
            autocomplete="nope"
            id="NIF"
            type="text"
            pInputText
            formControlName="NIF"
            [ngClass]="{
              'ng-invalid ng-dirty': hadSubmit && !validar('NIF')
            }"
          />
          <small
            class="p-error"
            *ngIf="hadSubmit && newEmpresa.controls.NIF.invalid"
            >Campo requerido.</small
          >
        </div>
        <div class="field pl-0 pt-0 pb-0 md:col-12">
          <label class="w-full" for="direccion">Direccion</label>
          <textarea
            id="direccion"
            pInputTextarea
            rows="3"
            cols="20"
            formControlName="direccion"
            [ngClass]="{
              '
                ng-invalid ng-dirty': hadSubmit && !validar('direccion')
            }"
          ></textarea>
          <small class="p-error" *ngIf="hadSubmit && !validar('direccion')"
            >Campo requerido.</small
          >
        </div>
        <div class="field pl-0 pt-1 pb-0 md:col-12">
          <label class="w-full" for="telefono">Telefono</label>
          <input
            autocomplete="nope"
            id="telefono"
            type="text"
            pInputText
            formControlName="telefono"
            [ngClass]="{
              'ng-invalid ng-dirty': hadSubmit && !validar('telefono')
            }"
          />
          <small
            class="p-error"
            *ngIf="hadSubmit && newEmpresa.controls.telefono.invalid"
            >Campo requerido.</small
          >
        </div>
        <div class="field pl-0 pt-1 pb-0 md:col-12">
          <label class="w-full" for="correo">Correo</label>
          <input
            autocomplete="nope"
            id="correo"
            type="text"
            pInputText
            formControlName="correo"
            [ngClass]="{
              'ng-invalid ng-dirty': hadSubmit && !validar('correo')
            }"
          />
          <small
            class="p-error"
            *ngIf="hadSubmit && newEmpresa.controls.correo.invalid"
            >Campo requerido.</small
          >
        </div>
        <div class="field pl-0 pt-0 pb-0 md:col-12">
          <label class="w-full" for="piePagina"
            >Pie de pagina para impresion</label
          >
          <p-editor
            id="piePagina"
            formControlName="piePagina"
            [ngClass]="{
              '
                ng-invalid ng-dirty': hadSubmit && !validar('piePagina')
            }"
            [style]="{ minHeight: '200px' }" 
          ></p-editor>
          <small class="p-error" *ngIf="hadSubmit && !validar('piePagina')"
            >Campo requerido.</small
          >
        </div>
        <div class="field pl-0 pt-1 pb-0 md:col-12">
          <label class="w-full" class="mb-3">Imagen</label>
          <p-fieldset>
            <ng-template pTemplate="header">
              <div class="flex align-items-center text-primary">
                <p-button
                  icon="pi pi-plus"
                  label="Seleccione"
                  styleClass="p-button-primary mr-3"
                  (click)="uploadAndResize()"
                  *ngIf="!imagenSubida"
                >
                </p-button>
              </div>
              <p-button
                *ngIf="imagenSubida"
                label="Eliminar"
                icon="pi pi-trash "
                styleClass="p-button-danger"
                (click)="onRemoveImg()"
              ></p-button>
            </ng-template>
            <div class="flex align-items-center justify-content-center">
              <img
                [src]="imagenSubida"
                alt=""
                class="p-3 shadow-lg rounded-lg w-full md:max-w-20rem"
              />
            </div>
          </p-fieldset>
        </div>
      </form>
      <div class="flex flex-column md:flex-row">
        <p-button
          class="m-2"
          label="Cancelar"
          icon="pi pi-times"
          styleClass="p-button-secondary mr-3"
          (onClick)="back()"
        ></p-button>
        <p-button
          class="m-2"
          label="Guardar"
          icon="pi pi-check"
          styleClass="p-button-primary"
          (click)="submit()"
        ></p-button>
      </div>
    </div>
  </div>
</div>
