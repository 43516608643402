import { Component, OnInit, inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { last } from 'rxjs';
import { CustomMessageService } from 'src/app/services/message.service';
import { UbicacionService } from 'src/app/services/ubicacion.service';

@Component({
  selector: 'app-mover-ubicacion',
  templateUrl: './mover-ubicacion.component.html',
  styleUrls: ['./mover-ubicacion.component.scss'],
})
export class MoverUbicacionComponent implements OnInit {
  private readonly activatedRoutes = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private readonly messageService = inject(CustomMessageService);
  private readonly Form = inject(FormBuilder);
  private readonly ubicacionService = inject(UbicacionService);

  public ubicaciones = [];

  public loading = false;
  public loadingUbis = false;
  public hadSubmit = false;

  public newUbicacion = this.Form.group({
    origen: [null, Validators.required],
    destino: [null, Validators.required],
  });

  ngOnInit(): void {
    this.cargarUbicaciones();
  }

  cargarUbicaciones() {
    this.loadingUbis = true;
    this.ubicacionService.getUbicacionesTipoAlmacen().subscribe({
      next: (response) => {
        this.ubicaciones = response;
        this.loadingUbis = false;
      },
      error: (error) => {
        this.messageService.error('Error al cargar ubicaciones');
      },
    });
  }

  validar() {
    if (
      this.newUbicacion.valid &&
      this.newUbicacion.get('origen').value ===
        this.newUbicacion.get('destino').value
    ) {
      return false;
    }
    return true;
  }

  back() {
    const lastUrl = sessionStorage.getItem('lastUrl');
    if (lastUrl && lastUrl != 'null') {
      if (lastUrl === '/mover') {
        this.router.navigateByUrl('/');
      } else {
        this.router.navigateByUrl(lastUrl);
      }
    } else {
      this.router.navigateByUrl('/');
    }
  }

  submit() {
    this.hadSubmit = true;
    if (this.newUbicacion.invalid || !this.validar()) {
      this.messageService.error('Revise los campos');
      return;
    }
    const body = this.newUbicacion.value;
    this.loading = true;
    this.ubicacionService.moverUbicacion(body).subscribe({
      next: (response) => {
        this.messageService.win('Ubicacion movida correctamente');
        this.newUbicacion.reset();
        this.hadSubmit = false;
        this.loading = false;
      },
      error: (error) => {
        this.messageService.error(error.error.detail);
      },
    });
  }
}
