import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Ubicacion, UbicacionesPaginadas } from '../interfaces/ubicacion.interface';

@Injectable({
  providedIn: 'root'
})
export class UbicacionService {
  private readonly http = inject(HttpClient);

  private API_URL = environment.API_URL;

  getUbicaciones(): Observable<Ubicacion[]> {
    const url = this.API_URL + `/ubicaciones`;
    return this.http.get<Ubicacion[]>(url);
  }
  getUbicacionesTipoAlmacen(): Observable<Ubicacion[]> {
    const url = this.API_URL + `/ubicaciones?tipo=almacen`; 
    return this.http.get<Ubicacion[]>(url);
  }

  getUbicacionesPaginadas(url): Observable<UbicacionesPaginadas> {
    return this.http.get<UbicacionesPaginadas>(url);
  }
  
  getUbicacion(id: number): Observable<Ubicacion> {
    const url = this.API_URL + `/ubicacion/${id}`;
    return this.http.get<Ubicacion>(url);
  }

  getUbicacionesByAlmacen(idAlmacen: number): Observable<Ubicacion[]> {
    const url = this.API_URL + `/ubicaciones/almacen/${idAlmacen}`;
    return this.http.get<Ubicacion[]>(url);
  }

  createUbicacion(newPresupeusto: Ubicacion): Observable<Ubicacion> {
    const url = this.API_URL + `/ubicacion`;
    return this.http.post<Ubicacion>(url, newPresupeusto);
  }

  editUbicacion(newPresupeusto: Ubicacion): Observable<Ubicacion> {
    const url = this.API_URL + `/ubicacion`;
    return this.http.put<Ubicacion>(url, newPresupeusto);
  }

  deleteUbicacion(id: number): Observable<Ubicacion> {
    const url = this.API_URL + `/ubicacion/${id}`;
    return this.http.delete<Ubicacion>(url);
  }

  moverUbicacion(body: any): Observable<any> {
    const url = this.API_URL + `/ubicacion/mover`;
    return this.http.post<any>(url, body);
  }
}
