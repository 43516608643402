<div
  class="flex align-items-center justify-content-center"
  style="height: 80vh"
  *ngIf="loading"
>
  <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
</div>

<div class="grid" *ngIf="!loading">
  <div class="col-12">
    <div class="card p-fluid">
      <h2>Mover Ubicacion</h2>
      <hr />
      <form [formGroup]="newUbicacion" class="">
        <div class="field pl-0 pt-0 pb-0 md:col-12">
          <label class="w-full" for="descripcion">Ubicacion de Origen</label>
          <div class="flex flex-row">
            <p-dropdown
              formControlName="origen"
              placeholder="Ingrese la ubicacion de origen"
              [options]="ubicaciones"
              optionLabel="ubicacionId"
              optionValue="id"
              class="w-full"
              [ngClass]="{
                'ng-invalid ng-dirty': (hadSubmit && newUbicacion.controls.origen.invalid) || !validar()
              }"
            ></p-dropdown>
            <div class="pt-2 ml-2" *ngIf="loadingUbis">
              <i class="pi pi-spin pi-spinner" style="font-size: 1.4rem"></i>
            </div>
          </div>
          <small
            class="p-error"
            *ngIf="hadSubmit && newUbicacion.controls.origen.invalid"
            >Campo requerido.</small
          >
          <small class="p-error" *ngIf="!validar()"
            >las ubicaciones no pueden ser iguales</small
          >
        </div>
        <div class="field pl-0 pt-0 pb-0 md:col-12">
          <label class="w-full" for="descripcion">Ubicacion de Destino</label>
          <div class="flex flex-row">
            <p-dropdown
              formControlName="destino"
              placeholder="Ingrese la ubicacion de destino"
              [options]="ubicaciones"
              optionLabel="ubicacionId"
              optionValue="id"
              class="w-full"
              [ngClass]="{
                'ng-invalid ng-dirty': (hadSubmit && newUbicacion.controls.destino.invalid) || !validar()
              }"
            ></p-dropdown>
            <div class="pt-2 ml-2" *ngIf="loadingUbis">
              <i class="pi pi-spin pi-spinner" style="font-size: 1.4rem"></i>
            </div>
          </div>
          <small
            class="p-error"
            *ngIf="hadSubmit && newUbicacion.controls.destino.invalid"
            >Campo requerido.</small
          >
          <small class="p-error" *ngIf="!validar()"
            >las ubicaciones no pueden ser iguales</small
          >
        </div>
      </form>
      <div class="flex flex-column md:flex-row">
        <p-button
          class="m-2"
          label="Cancelar"
          icon="pi pi-times"
          styleClass="p-button-secondary mr-3 "
          (onClick)="back()"
        ></p-button>
        <p-button
          class="m-2"
          label="Guardar"
          icon="pi pi-check"
          styleClass="p-button-primary"
          (click)="submit()"
        ></p-button>
      </div>
    </div>
  </div>
</div>
