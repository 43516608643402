<p-toast></p-toast>
<div class="layout-wrapper" [ngClass]="containerClass">
  <app-topbar></app-topbar>
  <div class="layout-sidebar">
    <app-sidebar></app-sidebar>
  </div>
  <div class="layout-main-container">
    <div class="layout-main">
      <!-- <app-breadcrumbs /> -->
      <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
  </div>
  <app-config></app-config>
  <div class="layout-mask"></div>
</div>
