<div class="grid">
  <div class="col-12">
    <div class="card px-6 py-6">
      <p-toolbar styleClass="toolbar-sin-bordes">
        <ng-template pTemplate="left">
          <div class="my-2 flex flex-row">
            <h2 class="mb-0">Calendario de Personal</h2>
            <!-- <i
              class="pi pi-spin pi-spinner mx-2"
              style="font-size: 1.5rem"
            ></i> -->
          </div>
        </ng-template>
        <ng-template pTemplate="right">
          <ng-container *ngIf="personas.length > 0">
            <app-calendar-menu
              [personas]="personas"
              (personasFiltradas)="filtrarCalendario($event)"
            />
          </ng-container>
        </ng-template>
      </p-toolbar>
      <hr class="mt-1" />
      <ng-container *ngIf="eventosReales.length > 0">
        <app-calendar
          [eventosReales]="eventosReales"
        ></app-calendar>
      </ng-container>
      <ng-container *ngIf="eventosReales.length == 0 && !loading">
        <div class="text-center">
          <h3>No hay eventos</h3>
        </div>
      </ng-container>
      <ng-container *ngIf="loading">
        <div
          class="flex align-items-center justify-content-center"
          style="height: 60vh"
        >
          <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
        </div>
      </ng-container>
    </div>
  </div>
</div>
