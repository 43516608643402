<div class="grid">
  <div class="col-12">
    <div class="card px-6 py-6">
      <p-toolbar styleClass="toolbar-sin-bordes mb-2">
        <ng-template pTemplate="left">
          <button
            pButton
            pRipple
            type="button"
            icon="pi pi-arrow-left"
            class="p-button-rounded mr-1"
            (click)="back()"
          ></button>
        </ng-template>

        <ng-template pTemplate="right">
        </ng-template>
      </p-toolbar>
      <div class="col-12">
        <div class="flex align-items-center justify-content-center">
          <p-image
            [src]="imagen"
            alt="Imagen"
            width="300vw"
            [preview]="true"
          ></p-image>
        </div>
      </div>
    </div>
  </div>
</div>
