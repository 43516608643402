<button
  pButton
  pRipple
  type="button"
  icon="pi pi-bars"
  class="p-button-rounded"
  (click)="sidebarVisible = true"
></button>

<p-sidebar [(visible)]="sidebarVisible" styleClass="w-30rem" position="right">
  <div class="mx-4 my-4">
    <h4>Filtros</h4>
    <hr class="mb-4" />
      <h5>Personal</h5>
      <p-multiSelect
        [options]="personas"
        [(ngModel)]="personasSeleccionadas"
        optionLabel="name"
        placeholder="Seleccione personal"
        selectedItemsLabel="{0} personas seleccionadas"
        class="multiselect-grande"
      ></p-multiSelect>
    <hr class="my-4" />
    <div class="flex flex-column md:flex-row my-2">
      <p-button
        class="ml-0"
        label="Aplicar"
        icon="pi pi-check"
        styleClass="p-button-primary"
        (onClick)="aplicarFiltros()"
      ></p-button>
      <p-button
        class="ml-2"
        label="Limpiar"
        icon="pi pi-times"
        styleClass="p-button-secondary mr-3"
        (onClick)="limpiarFiltros()"
      ></p-button>
    </div>
  </div>
</p-sidebar>
<!-- <ul class="right-options-sidebar">

</ul> -->
