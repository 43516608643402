<div
  class="flex align-items-center justify-content-center"
  style="height: 80vh"
  *ngIf="loading"
>
  <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
</div>

<div class="grid" *ngIf="!loading">
  <div class="col-12">
    <div class="card p-fluid">
      <h2>Generar Factura de Gasto</h2>
      <hr />

      <ng-container *ngIf="imagen">
        <p-image
          [src]="imagen"
          alt="Factura Del Gasto actual"
          width="100"
          [preview]="true"
        ></p-image>
        <hr />
      </ng-container>

      <form [formGroup]="newGastoFactura" class="">
        <div class="field pl-0 pt-1 pb-0 md:col-12">
          <label>Numero de la Factura</label>
          <input
            autocomplete="nope"
            id="numeroFactura"
            type="number"
            pInputText
            placeholder="Ingrese el numeroFactura"
            formControlName="numeroFactura"
            [ngClass]="{
              'ng-invalid ng-dirty': hadSubmit && !validar('numeroFactura')
            }"
          />
          <small class="p-error" *ngIf="hadSubmit && !validar('iva')"
            >Campo requerido.</small
          >
        </div>
        <div class="field pl-0 pt-1 pb-0 md:col-12">
          <label>IVA</label>
          <input
            autocomplete="nope"
            id="iva"
            type="number"
            pInputText
            placeholder="Ingrese el iva"
            formControlName="iva"
            [ngClass]="{
              'ng-invalid ng-dirty': hadSubmit && !validar('iva')
            }"
          />
          <small class="p-error" *ngIf="hadSubmit && !validar('iva')"
            >Campo requerido.</small
          >
        </div>
        <div class="field pl-0 pt-1 pb-0 md:col-12">
          <label>NIF del Proveedor</label>
          <div class="flex flex-row">
            <input
              autocomplete="nope"
              id="nif"
              type="text"
              pInputText
              placeholder="Ingrese el nif"
              formControlName="nif"
              [ngClass]="{
                'ng-invalid ng-dirty': hadSubmit && !validar('nif')
              }"
            />
          </div>
          <small class="p-error" *ngIf="hadSubmit && !validar('nif')"
            >Campo requerido.</small
          >
        </div>
      </form>
      <div class="flex flex-column md:flex-row">
        <p-button
          class="m-2"
          label="Cancelar"
          icon="pi pi-times"
          styleClass="p-button-secondary mr-3"
          (onClick)="back()"
        ></p-button>
        <p-button
          class="m-2"
          label="Guardar"
          icon="pi pi-check"
          styleClass="p-button-primary"
          (click)="submit()"
        ></p-button>
      </div>
    </div>
  </div>
</div>
