import { Injectable } from '@angular/core';

import { utcToZonedTime, format } from 'date-fns-tz';

@Injectable({
  providedIn: 'root',
})
export class FechaService {
  constructor() {}

  formatFecha(fecha: string): string {
    if (!fecha || fecha == null || fecha == undefined || fecha === '')
      return 'N/A';
    const fechaAbsolut = this.fechaAbsoluta(fecha);
    return format(fechaAbsolut, 'dd/MM/yyyy');
  }

  fechaAbsoluta(fecha: string): Date {
    // console.log('LA FECHA ABSOLUTA');
    if (!fecha || fecha == null || fecha == undefined || fecha === '')
      return null;
    let partesFecha;
    let partesHora;
    if (fecha.includes('T')) {
      partesFecha = fecha?.split('T')[0]?.split('-')
        ? fecha?.split('T')[0]?.split('-')
        : null;
      partesHora = fecha?.split('T')[1]?.split(':')
        ? fecha?.split('T')[1]?.split(':')
        : null;
    } else {
      partesFecha = fecha?.split(' ')[0]?.split('-')
        ? fecha?.split(' ')[0]?.split('-')
        : null;
      partesHora = fecha?.split(' ')[1]?.split(':')
        ? fecha?.split(' ')[1]?.split(':')
        : null;
    }
    const ano = partesFecha ? parseInt(partesFecha[0]) : null;
    const mes = partesFecha ? parseInt(partesFecha[1]) - 1 : null;
    const dia = partesFecha ? parseInt(partesFecha[2]) : null;
    const hora = partesHora ? parseInt(partesHora[0]) : null;
    const minutos = partesHora ? parseInt(partesHora[1]) : null;

    // console.log(`${dia} / ${mes + 1} / ${ano} - ${hora}:${minutos}`);

    const laFechaAbsoluta = new Date(ano, mes, dia, hora, minutos);

    // console.log(laFechaAbsoluta);
    return laFechaAbsoluta;
  }

  fechaAbsolutaToString(fecha: Date): string {
    if (!fecha || fecha == null || fecha == undefined) return null;
    // console.log('LA FECHA ABSOLUTA A ENVIAR');
    const ano = fecha.getFullYear();
    const mes = fecha.getMonth() + 1;
    const dia = fecha.getDate();
    const hora = fecha.getHours();
    const minutos = fecha.getMinutes();

    const laFechaAbsolutaToSend = `${ano}-${mes}-${dia}T${hora}:${minutos}:00+00:00`;
    // console.log(laFechaAbsolutaToSend);

    return laFechaAbsolutaToSend;
  }

  validarFechaFin(fechaInicio, fechaFin) {
    // Convertir las fechas a milisegundos usando Date.parse()

    if (fechaInicio === null || fechaInicio === undefined || fechaInicio === '')
      return false;
    if (fechaFin === null || fechaFin === undefined || fechaFin === '')
      return false;

    const fechaInicioMs = fechaInicio.getTime();
    const fechaFinMs = fechaFin.getTime();

    // console.log('las fechas son validas?', fechaFinMs ,' > ', fechaInicioMs , ' => ', fechaFinMs > fechaInicioMs);

    // Comparar las fechas
    return fechaFinMs >= fechaInicioMs;
  }
}
