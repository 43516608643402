import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Evento, Reserva } from '../interfaces/calendario.interface';
import { Presupuesto } from '../interfaces/presupuesto.interface';
@Injectable({
  providedIn: 'root',
})
export class CalendarioService {
  private readonly http = inject(HttpClient);

  private API_URL = environment.API_URL;

  // metodo para obtener los eventos del calendario de personal con el endpoint = calendarios/rrhhs
  getEventosPersonas(): Observable<Evento[]> {
    const url = this.API_URL + `/calendarios/rrhhs`;
    return this.http.get<Evento[]>(url);
  }

  cambiarReservaRRHH(reserva: Reserva): Observable<Reserva> {
    const url = this.API_URL + `/calendarios/rrhhs`;
    return this.http.put<Reserva>(url, reserva);
  }

  getEventosDePersonasFiltradas(personas: number[]): Observable<Evento[]> {
    const url = this.API_URL + `/calendarios/rrhhs`;
    return this.http.post<Evento[]>(url, {
      ids: personas,
    });
  }

  // region calendario de presupuestos --------------------------------------------------------------

  getEventosRecursos(): Observable<Evento[]> {
    const url = this.API_URL + `/calendarios/recursos`;
    return this.http.get<Evento[]>(url);
  }

  cambiarReservaRecurso(reserva: Reserva): Observable<Reserva> {
    const url = this.API_URL + `/calendarios/recursos`;
    return this.http.put<Reserva>(url, reserva);
  }

  getEventosDeRecursosFiltrados(presupuestos: number[]): Observable<Evento[]> {
    const url = this.API_URL + `/calendarios/recursos`;
    return this.http.post<Evento[]>(url, {
      ids: presupuestos,
    });
  }

  // region calendario de presupuestos --------------------------------------------------------------

  getEventosPresupuestos(): Observable<Presupuesto[]> {
    const url = this.API_URL + `/calendarios/presupuestos`;
    return this.http.get<Presupuesto[]>(url);
  }
}
