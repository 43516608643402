<!-- <p-table
      #dt
      [value]="eventosReales"
      [paginator]="true"
      [rows]="5"
      [rowsPerPageOptions]="[5, 10, 20, 30]"
      [totalRecords]="eventosReales.length"
      [rowHover]="true"
      selectionMode="single"
      [pSelectableRowDblClick]="true"
      [selection]="eventoSeleccionado"
      (onRowSelect)="showDate($event)"
      dataKey="id"
    >
      <ng-template pTemplate="header">
        <tr>
          <th>Personal</th>
          <th>Fecha Inicio</th>
          <th>Fecha Fin</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-evento>
        <tr [pSelectableRow]="evento">
          <td>
            {{ evento.title }}
          </td>
          <td>
            {{ evento.start | date : "dd/MM/yyyy HH:mm" }}
          </td>
          <td>
            {{ evento.end | date : "dd/MM/yyyy HH:mm" }}
          </td>
        </tr>
      </ng-template>
    </p-table> -->
<!-- <div id="external-events">
  <p>
    <strong>Draggable Events</strong>
  </p>

  <div class="fc-event fc-h-event fc-daygrid-event fc-daygrid-block-event">
    <div class="fc-event-main">My Event 1</div>
  </div>
  <div class="fc-event fc-h-event fc-daygrid-event fc-daygrid-block-event">
    <div class="fc-event-main">My Event 2</div>
  </div>
  <div class="fc-event fc-h-event fc-daygrid-event fc-daygrid-block-event">
    <div class="fc-event-main">My Event 3</div>
  </div>
  <div class="fc-event fc-h-event fc-daygrid-event fc-daygrid-block-event">
    <div class="fc-event-main">My Event 4</div>
  </div>
  <div class="fc-event fc-h-event fc-daygrid-event fc-daygrid-block-event">
    <div class="fc-event-main">My Event 5</div>
  </div>

  <p>
    <input type="checkbox" [(ngModel)]="theCheckbox" />

    <label for="drop-remove">remove after drop</label>
  </p>
</div> -->
<br />
<full-calendar
  #calendario
  [events]="eventos"
  [options]="calendarOptions"
  [deepChangeDetection]="true"
  style="max-height: 70vh"
>
  <ng-template #eventContent let-evento>
    <b>{{ evento.event.title }}</b>
    <!-- <b>
      <strong>Fecha Inicio:</strong>
      {{ evento.event.start | date : "dd/MM/yyyy HH:mm" }}
    </b>
    <b>
      <strong>Fecha Fin:</strong>
      {{ evento.event.end | date : "dd/MM/yyyy HH:mm" }}
    </b> -->
    <!-- <button (click)="show(evento.event.start)">evento</button> -->
  </ng-template>
</full-calendar>

<p-dialog
  [(visible)]="mostrarEvento"
  [style]="{ width: '450px' }"
  header="Informacion del Evento de reserva RRHH"
  [modal]="true"
  class="p-fluid"
  [contentStyle]="{ overflowY: 'visible' }"
>
  <ng-template pTemplate="content">
    <p><strong>Personal:</strong> {{ eventoSeleccionado.title }}</p>
    <p>
      <strong>Fecha Inicio:</strong>
      {{ eventoSeleccionado.start | date : "dd/MM/yyyy HH:mm" }}
    </p>
    <p>
      <strong>Fecha Fin:</strong>
      {{ eventoSeleccionado.end | date : "dd/MM/yyyy HH:mm" }}
    </p>
    <p>
      <strong>Presupuesto:</strong>
      {{ eventoSeleccionado.extendedProps.presupuesto.nombre }}
    </p>
    <p>
      {{ eventoSeleccionado.extendedProps.presupuesto.descripcion }}
    </p>
  </ng-template>
  

  <ng-template pTemplate="footer">
    <button
      pButton
      pRipple
      label="Cerrar"
      icon="pi pi-times"
      class="p-button-text p-button-primary"
      (click)="hideDialog()"
    ></button>
    <button
      pButton
      pRipple
      label="Ver Presupuesto"
      icon="pi pi-check"
      class="p-button-text p-button-primary"
      (click)="verPresupuesto(eventoSeleccionado.extendedProps.presupuesto.id)"
    ></button>
  </ng-template>
</p-dialog>
