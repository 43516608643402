import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import {
  LineaPresupuesto,
  LineaPresupuestoPost,
  Presupuesto,
  PresupuestoPost,
  PresupuestoPreparacionPost,
  PresupuestoPreparacionRespuesta,
  PresupuestoPreparacionPut,
  PresupuestosPaginados,
  PresupuestoPreparacionRRHH,
  PresupuestoPreparacionRRHHRespuesta,
  PresupuestoPreparacionRecurso,
  PresupuestoPreparacionRecursoRespuesta,
  PresupuestoPreparacionServicio,
  PresupuestoPreparacionServicioRespuesta,
  PresupuestoPreparacionServicioPut,
  Gasto,
  GastoPaginados,
  GastoFactura,
} from '../interfaces/presupuesto.interface';
import { PresupuestoPreparacion } from '../interfaces/presupuesto.interface';

@Injectable({
  providedIn: 'root',
})
export class PresupuestoService {
  private readonly http = inject(HttpClient);

  private API_URL = environment.API_URL;

  getPresupuestos(): Observable<Presupuesto[]> {
    const url = this.API_URL + `/presupuestos`;
    return this.http.get<Presupuesto[]>(url);
  }

  getPresupuesto(id: number): Observable<Presupuesto> {
    const url = this.API_URL + `/presupuesto/${id}`;
    return this.http.get<Presupuesto>(url);
  }

  getPresupuestosPaginados(url): Observable<PresupuestosPaginados> {
    return this.http.get<PresupuestosPaginados>(url);
  }

  getPresupuestosByProyecto(idProyecto: number): Observable<Presupuesto[]> {
    const url = this.API_URL + `/presupuestos/proyecto/${idProyecto}`;
    return this.http.get<Presupuesto[]>(url);
  }

  createPresupuesto(newPresupeusto: PresupuestoPost): Observable<Presupuesto> {
    const url = this.API_URL + `/presupuesto`;
    return this.http.post<Presupuesto>(url, newPresupeusto);
  }

  editPresupuesto(newPresupeusto: Presupuesto): Observable<Presupuesto> {
    const url = this.API_URL + `/presupuesto`;
    return this.http.put<Presupuesto>(url, newPresupeusto);
  }

  deletePresupuesto(id: number): Observable<Presupuesto> {
    const url = this.API_URL + `/presupuesto/${id}`;
    return this.http.delete<Presupuesto>(url);
  }

  //-------------------------------------------------------------------------------------------

  getLineasPresupuestosByPresupuesto(
    id: number
  ): Observable<LineaPresupuesto[]> {
    const url = this.API_URL + `/presupuestos/presupuesto_linea/${id}`;
    return this.http.get<LineaPresupuesto[]>(url);
  }

  createLineaPresupuesto(
    newLineaPresupuesto: LineaPresupuestoPost
  ): Observable<LineaPresupuesto> {
    const url = this.API_URL + `/presupuesto_linea`;
    return this.http.post<LineaPresupuesto>(url, newLineaPresupuesto);
  }

  deleteLineaPresupuesto(id: number): Observable<LineaPresupuesto> {
    const url = this.API_URL + `/presupuesto_linea/${id}`;
    return this.http.delete<LineaPresupuesto>(url);
  }

  // -------------------------------------------------------------------------------------------

  agregarGrupoProductosAPresupeusto(body): Observable<Presupuesto> {
    const url = this.API_URL + `/presupuesto_add_grupo_producto`;
    return this.http.post<Presupuesto>(url, body);
  }

  aceptarPresupuesto(id: number): Observable<any> {
    const url = this.API_URL + `/presupuesto/aceptar/${id}`;
    return this.http.post<any>(url, {});
  }

  prepararPresupuesto(id: number): Observable<any> {
    const url = this.API_URL + `/presupuesto/preparar/${id}`;
    return this.http.post<any>(url, {});
  }

  generarTareasPreparacionPresupuesto(id: number): Observable<any> {
    const url = this.API_URL + `/presupuesto/tareas/${id}`;
    return this.http.post<any>(url, {});
  }

  editarLineaPresupuesto(body): Observable<LineaPresupuesto> {
    const url = this.API_URL + `/presupuesto_linea`;
    return this.http.put<LineaPresupuesto>(url, body);
  }

  cambiarFechas(body): Observable<LineaPresupuesto> {
    const url = this.API_URL + `/presupuesto_linea/cambiar_fecha`;
    return this.http.post<LineaPresupuesto>(url, body);
  }
  
  despacharPresupuesto(id: number): Observable<any> {
    const url = this.API_URL + `/presupuesto/despachar/${id}`;
    return this.http.post<any>(url, {});
  }

  // ---------------------------------------------------------------------------------------------

  getPresupuestoPreparacionesByPresupuesto(
    id: number
  ): Observable<PresupuestoPreparacion[]> {
    const url =
      this.API_URL + `/presupuestos/presupuesto_linea_mercancia/${id}`;
    return this.http.get<PresupuestoPreparacion[]>(url);
  }

  createPresupuestoPreparacion(
    newPresupuestoPreparacion: PresupuestoPreparacionPost
  ): Observable<PresupuestoPreparacionRespuesta> {
    const url = this.API_URL + `/presupuesto_linea_mercancia`;
    return this.http.post<PresupuestoPreparacionRespuesta>(
      url,
      newPresupuestoPreparacion
    );
  }

  editPresupuestoPreparacion(
    newPresupuestoPreparacion: PresupuestoPreparacionPut
  ): Observable<PresupuestoPreparacionRespuesta> {
    const url = this.API_URL + `/presupuesto_linea_mercancia`;
    return this.http.put<PresupuestoPreparacionRespuesta>(
      url,
      newPresupuestoPreparacion
    );
  }

  editPresupuestoPreparacionFaltante(
    newPresupuestoPreparacion: PresupuestoPreparacionPut
  ): Observable<PresupuestoPreparacionRespuesta> {
    const url = this.API_URL + `/preparacion_faltante`;
    return this.http.put<PresupuestoPreparacionRespuesta>(
      url,
      newPresupuestoPreparacion
    );
  }

  deletePresupuestoPreparacion(id: number): Observable<PresupuestoPreparacion> {
    const url = this.API_URL + `/presupuesto_linea_mercancia/${id}`;
    return this.http.delete<PresupuestoPreparacion>(url);
  }

  deletePresupuestoPreparacionFaltante(
    id: number
  ): Observable<PresupuestoPreparacion> {
    const url = this.API_URL + `/preparacion_faltante/${id}`;
    return this.http.delete<PresupuestoPreparacion>(url);
  }

  // ------------------------------------------------------------------------------------

  getPresupuestoPreparacionesRRHHByPresupuesto(
    id: number
  ): Observable<PresupuestoPreparacionRRHH[]> {
    const url = this.API_URL + `/presupuestos/presupuesto_linea_rrhh/${id}`;
    return this.http.get<PresupuestoPreparacionRRHH[]>(url);
  }

  createPresupuestoPreparacionRRHH(
    newPresupuestoPreparacion: PresupuestoPreparacionPost
  ): Observable<PresupuestoPreparacionRRHHRespuesta> {
    const url = this.API_URL + `/presupuesto_linea_rrhh`;
    return this.http.post<PresupuestoPreparacionRRHHRespuesta>(
      url,
      newPresupuestoPreparacion
    );
  }

  editPresupuestoPreparacionRRHH(
    newPresupuestoPreparacion: PresupuestoPreparacionPut
  ): Observable<PresupuestoPreparacionRRHHRespuesta> {
    const url = this.API_URL + `/presupuesto_linea_rrhh`;
    return this.http.put<PresupuestoPreparacionRRHHRespuesta>(
      url,
      newPresupuestoPreparacion
    );
  }

  editPresupuestoPreparacionRRHHFaltante(
    newPresupuestoPreparacion: PresupuestoPreparacionPut
  ): Observable<PresupuestoPreparacionRRHHRespuesta> {
    const url = this.API_URL + `/rrhh_faltante`;
    return this.http.put<PresupuestoPreparacionRRHHRespuesta>(
      url,
      newPresupuestoPreparacion
    );
  }

  deletePresupuestoPreparacionRRHH(
    id: number
  ): Observable<PresupuestoPreparacionRRHH> {
    const url = this.API_URL + `/presupuesto_linea_rrhh/${id}`;
    return this.http.delete<PresupuestoPreparacionRRHH>(url);
  }

  deletePresupuestoPreparacionRRHHFaltante(
    id: number
  ): Observable<PresupuestoPreparacionRRHHRespuesta> {
    const url = this.API_URL + `/rrhh_faltante/${id}`;
    return this.http.delete<PresupuestoPreparacionRRHHRespuesta>(url);
  }

  asignarPersonalALineaRRHHFaltante(body): Observable<any> {
    const url = this.API_URL + `/rrhh_faltante/asignar_persona`;
    return this.http.post<any>(url, body);
  }

  asignarFechasALineaRRHHFaltante(body): Observable<any> {
    const url = this.API_URL + `/rrhh_faltante/actualizar_fecha`;
    return this.http.put<any>(url, body);
  }

  asignarPersonalALineasRRHHFaltantes(idPresupeusto): Observable<any> {
    const url = this.API_URL + `/presupuesto_linea/generar_reservas_rrhh`;
    return this.http.post<any>(url, {id: idPresupeusto});
  }

  cambiarFechasALineasRRHHFaltantes(body): Observable<any> {
    const url = this.API_URL + `/presupuesto_linea/actualizar_fecha_rrhh`;
    return this.http.put<any>(url, body);
  }
  
  cambiarFechasDeLineasRRHHFaltantesDelPresupuesto(body): Observable<any> {
    const url = this.API_URL + `/presupuesto/actualizar_fecha_rrhh`;
    return this.http.put<any>(url, body);
  }

  asignarPersonalDeLineasRRHHFaltantesDelPresupuesto(idPresupuesto): Observable<any> {
    const url = this.API_URL + `/presupuesto/generar_reservas_rrhh`;
    return this.http.post<any>(url, {id: idPresupuesto});
  }

  //------------------------------------------------------------------------------------------

  getPresupuestoPreparacionesRecursosByPresupuesto(
    id: number
  ): Observable<PresupuestoPreparacionRecurso[]> {
    const url = this.API_URL + `/presupuestos/presupuesto_linea_recurso/${id}`;
    return this.http.get<PresupuestoPreparacionRecurso[]>(url);
  }

  createPresupuestoPreparacionRecurso(
    newPresupuestoPreparacionRecurso: PresupuestoPreparacionPost
  ): Observable<PresupuestoPreparacionRecursoRespuesta> {
    const url = this.API_URL + `/presupuesto_linea_recurso`;
    return this.http.post<PresupuestoPreparacionRecursoRespuesta>(
      url,
      newPresupuestoPreparacionRecurso
    );
  }

  editPresupuestoPreparacionRecurso(
    newPresupuestoPreparacionRecurso: PresupuestoPreparacionPut
  ): Observable<PresupuestoPreparacionRecursoRespuesta> {
    const url = this.API_URL + `/presupuesto_linea_recurso`;
    return this.http.put<PresupuestoPreparacionRecursoRespuesta>(
      url,
      newPresupuestoPreparacionRecurso
    );
  }

  editPresupuestoPreparacionRecursoFaltante(
    newPresupuestoPreparacionRecurso: PresupuestoPreparacionPut
  ): Observable<PresupuestoPreparacionRecursoRespuesta> {
    const url = this.API_URL + `/recursos_faltante`;
    return this.http.put<PresupuestoPreparacionRecursoRespuesta>(
      url,
      newPresupuestoPreparacionRecurso
    );
  }

  deletePresupuestoPreparacionRecurso(
    id: number
  ): Observable<PresupuestoPreparacionRecurso> {
    const url = this.API_URL + `/presupuesto_linea_recurso/${id}`;
    return this.http.delete<PresupuestoPreparacionRecurso>(url);
  }

  deletePresupuestoPreparacionRecursoFaltante(
    id: number
  ): Observable<PresupuestoPreparacionRecurso> {
    const url = this.API_URL + `/recursos_faltante/${id}`;
    return this.http.delete<PresupuestoPreparacionRecurso>(url);
  }

  //------------------------------------------------------------------------------------------

  getPresupuestoPreparacionesServiciosByPresupuesto(
    id: number
  ): Observable<PresupuestoPreparacionServicio[]> {
    const url = this.API_URL + `/presupuestos/presupuesto_linea_servicio/${id}`;
    return this.http.get<PresupuestoPreparacionServicio[]>(url);
  }

  createPresupuestoPreparacionServicio(
    newPresupuestoPreparacionServicio: PresupuestoPreparacionPost
  ): Observable<PresupuestoPreparacionServicioRespuesta> {
    const url = this.API_URL + `/presupuesto_linea_servicio`;
    return this.http.post<PresupuestoPreparacionServicioRespuesta>(
      url,
      newPresupuestoPreparacionServicio
    );
  }

  editPresupuestoPreparacionServicio(
    newPresupuestoPreparacionServicio: PresupuestoPreparacionServicioPut
  ): Observable<PresupuestoPreparacionServicioRespuesta> {
    const url = this.API_URL + `/presupuesto_linea_servicio`;
    return this.http.put<PresupuestoPreparacionServicioRespuesta>(
      url,
      newPresupuestoPreparacionServicio
    );
  }

  editPresupuestoPreparacionServicioFaltante(
    newPresupuestoPreparacionServicio: PresupuestoPreparacionServicioPut
  ): Observable<PresupuestoPreparacionServicioRespuesta> {
    const url = this.API_URL + `/servicios_faltante`;
    return this.http.put<PresupuestoPreparacionServicioRespuesta>(
      url,
      newPresupuestoPreparacionServicio
    );
  }

  deletePresupuestoPreparacionServicio(
    id: number
  ): Observable<PresupuestoPreparacionServicio> {
    const url = this.API_URL + `/presupuesto_linea_servicio/${id}`;
    return this.http.delete<PresupuestoPreparacionServicio>(url);
  }

  deletePresupuestoPreparacionServicioFaltante(
    id: number
  ): Observable<PresupuestoPreparacionRecurso> {
    const url = this.API_URL + `/servicios_faltante/${id}`;
    return this.http.delete<PresupuestoPreparacionRecurso>(url);
  }

  // ------------------------------------------------------------------------------------

  getGastosPaginados(url): Observable<GastoPaginados> {
    return this.http.get<GastoPaginados>(url);
  }
  getGastosByPresupuesto(id): Observable<Gasto[]> {
    const url = this.API_URL + `/presupuestos/gastos_presupuesto/${id}`;
    return this.http.get<Gasto[]>(url);
  }
  deleteGasto(id): Observable<Gasto> {
    const url = this.API_URL + `/gastos_presupuesto/${id}`;
    return this.http.delete<Gasto>(url);
  }
  createGastoFactura(body): Observable<GastoFactura> {
    const url = this.API_URL + `/gastos_presupuesto/generar_factura`;
    return this.http.post<GastoFactura>(url, body);
  }
  getGasto(idGasto): Observable<Gasto> {
    const url = this.API_URL + `/gastos_presupuesto/${idGasto}`;
    return this.http.get<Gasto>(url);
  }
}
