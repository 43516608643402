import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Empresa } from '../interfaces/empresa';

@Injectable({
  providedIn: 'root'
})
export class EmpresaService {
  private readonly http = inject(HttpClient);

  private API_URL = environment.API_URL;

  getEmpresa(): Observable<Empresa> {
    const url = this.API_URL + `/empresas`;
    return this.http.get<Empresa>(url);
  }

  editEmpresa(newEmpresa: Empresa): Observable<Empresa> {
    const url = this.API_URL + `/empresa`;
    return this.http.put<Empresa>(url, newEmpresa);
  }
}
