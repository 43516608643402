import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AppLayoutComponent } from './layout/app.layout.component';
import { AuthGuard } from './guards/auth.guard';
import { NotfoundComponent } from './layout/notfound/notfound.component';
import { EmpresaComponent } from './layout/empresa/empresa.component';
import { VerImagenComponent } from './layout/ver-imagen/ver-imagen.component';
import { GastosComponent } from './layout/presupuestos/mostrar-presupuesto/gastos/gastos.component';
import { GastosFacturaComponent } from './layout/presupuestos/mostrar-presupuesto/gastos-factura/gastos-factura.component';
import { MoverUbicacionComponent } from './layout/mover-ubicacion/mover-ubicacion.component';

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: '',
          component: AppLayoutComponent,
          canActivate: [AuthGuard],
          children: [
            {
              path: '',
              loadChildren: () =>
                import('./layout/dashboard/dashboard.module').then(
                  (m) => m.DashboardModule
                ),
            },
            {
              path: 'tareas',
              loadChildren: () =>
                import('./layout/tareas/tareas.module').then(
                  (m) => m.TareasModule
                ),
            },
            {
              path: 'ordenes-trabajo',
              loadChildren: () =>
                import('./layout/ordenes-trabajo/ordenes-trabajo.module').then(
                  (m) => m.OrdenesTrabajoModule
                ),
            },
            {
              path: 'productos',
              loadChildren: () =>
                import('./layout/productos/productos.module').then(
                  (m) => m.ProductosModule
                ),
            },
            {
              path: 'mercancias',
              loadChildren: () =>
                import('./layout/mercancias/mercancias.module').then(
                  (m) => m.MercanciasModule
                ),
            },
            {
              path: 'clientes',
              loadChildren: () =>
                import('./layout/clientes/clientes.module').then(
                  (m) => m.ClientesModule
                ),
            },
            {
              path: 'proveedores',
              loadChildren: () =>
                import('./layout/proveedores/proveedores.module').then(
                  (m) => m.ProveedoresModule
                ),
            },
            {
              path: 'presupuestos',
              loadChildren: () =>
                import('./layout/presupuestos/presupuestos.module').then(
                  (m) => m.PresupuestosModule
                ),
            },
            {
              path: 'proyectos',
              loadChildren: () =>
                import('./layout/proyectos/proyectos.module').then(
                  (m) => m.ProyectosModule
                ),
            },
            {
              path: 'procesos',
              loadChildren: () =>
                import('./layout/procesos/procesos.module').then(
                  (m) => m.ProcesosModule
                ),
            },
            {
              path: 'grupos-productos',
              loadChildren: () =>
                import(
                  './layout/grupos-productos/grupos-productos.module'
                ).then((m) => m.GruposProductosModule),
            },
            {
              path: 'facturas-emitidas',
              loadChildren: () =>
                import(
                  './layout/facturas-emitidas/facturas-emitidas.module'
                ).then((m) => m.FacturasEmitidasModule),
            },
            {
              path: 'facturas-recibidas',
              loadChildren: () =>
                import(
                  './layout/facturas-recibidas/facturas-recibidas.module'
                ).then((m) => m.FacturasRecibidasModule),
            },
            {
              path: 'ordenes-compras',
              loadChildren: () =>
                import('./layout/ordenes-compras/ordenes-compras.module').then(
                  (m) => m.OrdenesComprasModule
                ),
            },
            {
              path: 'operaciones-internas',
              loadChildren: () =>
                import(
                  './layout/operaciones-internas/operaciones-internas.module'
                ).then((m) => m.OperacionesInternasModule),
            },
            {
              path: 'almacenes',
              loadChildren: () =>
                import('./layout/almacenes/almacenes.module').then(
                  (m) => m.AlmacenesModule
                ),
            },
            {
              path: 'ubicaciones',
              loadChildren: () =>
                import('./layout/ubicaciones/ubicaciones.module').then(
                  (m) => m.UbicacionesModule
                ),
            },
            {
              path: 'personas',
              loadChildren: () =>
                import('./layout/personas/personas.module').then(
                  (m) => m.PersonasModule
                ),
            },
            {
              path: 'calendario-personal',
              loadComponent: () =>
                import('./layout/calendario/calendario.component').then(
                  (m) => m.CalendarioComponent
                ),
            },
            {
              path: 'calendario-recursos',
              loadComponent: () =>
                import('./layout/calendario-recursos/calendario-recursos.component').then(
                  (m) => m.CalendarioRecursosComponent
                ),
            },
            {
              path: 'calendario-presupuestos',
              loadComponent: () =>
                import('./layout/calendario-presupuestos/calendario-presupuestos.component').then(
                  (m) => m.CalendarioPresupuestosComponent
                ),
            },
            {
              path: 'board-tareas-estado',
              loadComponent: () =>
                import('./layout/board-tareas-estado/board-tareas-estado.component').then(
                  (m) => m.BoardTareasEstadoComponent
                ),
            },
            {
              path: 'empresa',
              component: EmpresaComponent,
            },
            {
              path: 'imagen',
              component: VerImagenComponent,
            },
            {
              path: 'gastos',
              component: GastosComponent,
            },
            {
              path: 'gastos/gastos-factura/:idGasto',
              component: GastosFacturaComponent,
            },
            {
              path: 'mover',
              component: MoverUbicacionComponent,
            },

          ],
        },
        {
          path: 'auth',
          loadChildren: () =>
            import('./auth/auth.module').then((m) => m.AuthModule),
        },
        // { path: 'notfound', component: NotfoundComponent },
        { path: '**', component: NotfoundComponent },
      ],
      {
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
        onSameUrlNavigation: 'reload',
      }
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
