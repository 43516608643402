import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { sub } from 'date-fns';
import { ConfirmationService } from 'primeng/api';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';
import { Gasto } from 'src/app/interfaces/presupuesto.interface';
import { FechaService } from 'src/app/services/fecha.service';
import { CustomMessageService } from 'src/app/services/message.service';
import { PresupuestoService } from 'src/app/services/presupuesto.service';
import { ProductoService } from 'src/app/services/producto.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-gastos',
  templateUrl: './gastos.component.html',
  styleUrls: ['./gastos.component.scss'],
})
export class GastosComponent implements OnInit, OnDestroy {
  @ViewChild('dt') dt: Table | undefined;

  // servicios requeridos
  private readonly presupuestoService = inject(PresupuestoService);
  private readonly messageService = inject(CustomMessageService);
  private readonly router = inject(Router);

  @Input() presupuestoID: number;
  @Output() totalGastosEmit: EventEmitter<number> = new EventEmitter();

  public totalGastos = 0;

  public subs: Subscription[] = [];

  public gastos: Gasto[] = null;

  public loading: boolean = true;

  url: string = environment.API_URL;

  totalRecords: number = 0;
  mostrarDel: number = 1;
  mostrarAl: number = 10;

  valorNulleable = null;
  valorNulleable2 = null;
  valorNulleable3 = null;

  facturaSnPrecargado = true;

  selectFacturaSn = [
    'Si',
    'No',
  ]

  // definicion de columnas que usa la tabla de lineas de presupuesto
  public cols: Object[];

  ngOnInit(): void {
    this.cols = [{ field: 'importe', header: 'Importe' }];
    this.cols = [{ field: 'presupeusto', header: 'Presupuesto' }];
    this.cols = [{ field: 'facturaSn', header: 'Facturado' }];

    if (this.presupuestoID) {
      this.cargarGastosDelPresupuesto();
    } else {
      this.valorNulleable = 'No'
    }
  }

  navegarFacturarGasto(gasto) {
    const url = this.presupuestoID
      ? `/presupuestos/mostrar/${this.presupuestoID}`
      : '/gastos';
    sessionStorage.setItem('lastUrl', url);
    this.router.navigateByUrl('/gastos/gastos-factura/' + gasto.id)
  }

  cargarGastosDelPresupuesto() {
    this.loading = true;
    this.subs.push(
      this.presupuestoService
        .getGastosByPresupuesto(this.presupuestoID)
        .subscribe({
          next: (res) => {
            this.loading = false;
            this.gastos = res;

            this.totalGastos = this.gastos.reduce((acumulador, gasto) => {
              return acumulador + gasto.importe;
            }, 0);

            this.totalGastosEmit.emit(this.totalGastos)
          },
          error: (error) => {
            console.error(error);
            this.messageService.error(error.error.detail);
          },
        })
    );
  }

  applyFilterGlobal($event: any, stringVal: any) {
    this.dt!.filterGlobal(
      ($event.target as HTMLInputElement).value,
      'contains'
    );
  }

  clear(table: Table) {
    table.clear();
    // (document.getElementById('buscador') as any).value = '';
    this.valorNulleable = null;
    this.valorNulleable2 = null;
    this.valorNulleable3 = null;
    this.facturaSnPrecargado = false;
    // (document.getElementById('buscadorCliente') as any).value = '';
  }

  loadCustomers(event: any) {
    this.loading = true;

    const first = event.first ? event.first : 0;
    const rows = event.rows ? event.rows : 1;
    const page = first / rows + 1;

    var endPoint =
      this.url +
      `/gastos_presupuestos_paginate?page=${page}&itemsPerPage=${rows}`;

    // if (event.globalFilter) {
    //   endPoint = endPoint + `&importe=${event.globalFilter}`;
    // }

    if (
      event.filters['importe'] &&
      event.filters['importe'] != undefined &&
      event.filters['importe'].value != null
    ) {
      endPoint = endPoint + `&importe=${event.filters['importe'].value}`;
    } 

    if (
      event.filters['presupuesto'] &&
      event.filters['presupuesto'] != undefined &&
      event.filters['presupuesto'].value != null
    ) {
      endPoint = endPoint + `&presupuesto=${event.filters['presupuesto'].value}`;
    } 

    if (
      this.valorNulleable != null &&
      this.facturaSnPrecargado
    ) {
      endPoint = endPoint + `&facturaSn=${this.valorNulleable}`;
      this.facturaSnPrecargado = false
    } 

    if (
      event.filters['facturaSn'] &&
      event.filters['facturaSn'] != undefined &&
      event.filters['facturaSn'].value != null &&
      !this.facturaSnPrecargado
    ) {
      endPoint = endPoint + `&facturaSn=${event.filters['facturaSn'].value}`;
    } 

    if (event.sortField) {
      endPoint = endPoint + `&ordenCampo=${event.sortField}`;

      if (event.sortOrder == 1) {
        endPoint = endPoint + '&ordenTipo=ASC';
      }

      if (event.sortOrder == -1) {
        endPoint = endPoint + '&ordenTipo=DESC';
      }
    }
    this.cargarGastosP(endPoint, first, rows);
  }

  cargarGastosP(url: string, first: number, rows: number) {
    this.subs.push(
      this.presupuestoService.getGastosPaginados(url).subscribe(
        (response) => {
          console.log(response);
          this.gastos = response.data;
          this.loading = false;
          this.totalRecords = response.totalItems;
          this.loading = false;
          this.mostrarDel = first + 1;
          this.mostrarAl = this.mostrarDel + rows - 1;
          if (this.mostrarAl > this.totalRecords) {
            this.mostrarAl = this.totalRecords;
          }
        },
        (error) => {
          console.error(error);
          this.loading = false;
          this.messageService.error(error.error.detail);
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
