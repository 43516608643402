import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import multiMonthPlugin from '@fullcalendar/multimonth';
import { FullCalendarComponent } from '@fullcalendar/angular';
import esLocale from '@fullcalendar/core/locales/es';
import { formatDate } from '@fullcalendar/core';
import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { FullCalendarModule } from '@fullcalendar/angular';
import {
  Calendar,
  CalendarOptions,
  EventInput,
  EventSourceInput,
} from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import { PrimeNgModule } from '../shared/prime-ng/prime-ng.module';
import { CalendarioService } from 'src/app/services/calendario.service';
import { CustomMessageService } from 'src/app/services/message.service';
import { FechaService } from 'src/app/services/fecha.service';
import { Evento } from 'src/app/interfaces/calendario.interface';
import { CalendarComponent } from './calendar/calendar.component';
import { CalendarMenuComponent } from './calendar-menu/calendar-menu.component';
import { Persona } from 'src/app/interfaces/persona.interface';
import { filter } from 'rxjs';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-calendario',
  templateUrl: './calendario.component.html',
  styleUrls: ['./calendario.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FullCalendarModule,
    PrimeNgModule,
    CalendarComponent,
    CalendarMenuComponent,
  ],
})
export class CalendarioComponent implements OnInit {
  private readonly calendarioService = inject(CalendarioService);
  private readonly messageService = inject(CustomMessageService);
  private readonly fechaService = inject(FechaService);

  public eventosReales = [];
  public personas: Persona[] = [];
  public loading = true;
  public loadingEventos = false;

  public colores: string[] = [
    'red',
    'blue',
    'green',
    'yellow',
    'purple',
    'orange',
    'pink',
    'brown',
    'cyan',
    'teal',
    'indigo',
    'lime',
    'amber',
    'deep-orange',
    'deep-purple',
    'light-blue',
    'light-green',
    'blue-grey',
    'grey',
    'black',
  ];

  ngOnInit(): void {
    this.cargarEventos();
  }

  cargarEventos() {
    this.calendarioService.getEventosPersonas().subscribe({
      next: (eventos) => {
        let i = 0;
        console.log('eventos => ', eventos);
        this.personas = []
        this.personas = eventos as Persona[];
        eventos.forEach((persona) => {
          //@ts-ignore
          persona.name = persona.nombre + ' ' + persona.apellido;
          const color = this.colores[i % this.colores.length];
          persona.reservas.forEach((reserva) => {
            this.eventosReales.push({
              id: reserva.id,
              title: `${persona.nombre} ${persona.apellido}`,
              start: this.fechaService.fechaAbsoluta(reserva.inicio),
              end: this.fechaService.fechaAbsoluta(reserva.fin),
              allDay: false,
              // asignar de forma aleatoria un color a cada evento
              color: color,
              // datos originales de la reserva
              extendedProps: reserva,
            });
            i++;
          });
        });
        this.loading = false;
        console.log(this.eventosReales);
      },
      error: (error) => {
        this.loading = false;
        console.error(error);
        this.messageService.error(error.error.detail);
      },
    });
  }

  filtrarCalendario(personas: number[]) {
    this.eventosReales = [];
    this.loading = true;
    if (personas.length === 0) {
      this.cargarEventos();
    } else {
      this.calendarioService.getEventosDePersonasFiltradas(personas).subscribe({
        next: (eventos) => {
          let i = 0;
          eventos.forEach((persona) => {
            const color = this.colores[i % this.colores.length];
            persona.reservas.forEach((reserva) => {
              this.eventosReales.push({
                id: reserva.id,
                title: `${persona.nombre} ${persona.apellido}`,
                start: this.fechaService.fechaAbsoluta(reserva.inicio),
                end: this.fechaService.fechaAbsoluta(reserva.fin),
                allDay: false,
                // asignar de forma aleatoria un color a cada evento
                color: color,
                // datos originales de la reserva
                extendedProps: reserva,
              });
              i++;
            });
          });
          this.loading = false;
          console.log(this.eventosReales);
        },
        error: (error) => {
          this.loading = false;
          console.error(error);
          this.messageService.error(error.error.detail);
        },
      });
    }
  }
}
