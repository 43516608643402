import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrimeNgModule } from '../../shared/prime-ng/prime-ng.module';
import { Persona } from 'src/app/interfaces/persona.interface';

@Component({
  selector: 'app-calendar-menu',
  standalone: true,
  imports: [CommonModule, PrimeNgModule],
  templateUrl: './calendar-menu.component.html',
  styleUrls: ['./calendar-menu.component.scss'],
})
export class CalendarMenuComponent implements OnInit{
  @Input() personas!: Persona[]
  @Output() personasFiltradas: EventEmitter<number[]> = new EventEmitter<number[]>();
  sidebarVisible: boolean = false;

  public personasSeleccionadas: number[] = [];

  ngOnInit() {
    console.log('personas recibidas => ', this.personas);
  }

  filtrarPersonas() {
    this.personasFiltradas.emit(this.personasSeleccionadas);
  }

  aplicarFiltros() {
    this.sidebarVisible = false;
    if (this.personasSeleccionadas.length > 0) this.filtrarPersonas();
  }

  limpiarFiltros() {    
    this.sidebarVisible = false;
    this.personasSeleccionadas = [];
    this.filtrarPersonas();
  }
}
