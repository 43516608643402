import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrimeNgModule } from '../../shared/prime-ng/prime-ng.module';

import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import multiMonthPlugin from '@fullcalendar/multimonth';
import { FullCalendarComponent } from '@fullcalendar/angular';
import esLocale from '@fullcalendar/core/locales/es';
import { formatDate } from '@fullcalendar/core';
import { FullCalendarModule } from '@fullcalendar/angular';
import {
  Calendar,
  CalendarOptions,
  EventInput,
  EventSourceInput,
} from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import { CalendarioService } from 'src/app/services/calendario.service';
import { CustomMessageService } from 'src/app/services/message.service';
import { FechaService } from 'src/app/services/fecha.service';
import { Evento, Reserva } from 'src/app/interfaces/calendario.interface';
import { Button } from 'primeng/button';
import { id } from 'date-fns/locale';
import { color } from 'html2canvas/dist/types/css/types/color';
import { Router } from '@angular/router';

@Component({
  selector: 'app-calendar',
  standalone: true,
  imports: [CommonModule, PrimeNgModule, FullCalendarModule],
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit {
  @ViewChild('calendario', { static: true })
  elCalendario: FullCalendarComponent;

  @Input() eventosReales!: any[];

  @Output() loadingEventos: EventEmitter<boolean> = new EventEmitter<boolean>();

  private readonly calendarioService = inject(CalendarioService);
  private readonly messageService = inject(CustomMessageService);
  private readonly fechaService = inject(FechaService);
  private readonly router = inject(Router);

  public mostrarEvento = false;

  public calendarPlugins = [
    dayGridPlugin,
    timeGridPlugin,
    listPlugin,
    interactionPlugin,
    multiMonthPlugin,
  ];

  public eventoSeleccionado!: any;

  public apiDelCalendario: Calendar;

  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    dateClick: this.mostrarFecha.bind(this),
    events: this.eventosReales,
    plugins: this.calendarPlugins,
    locale: esLocale,
    slotMinTime: '6:00:00',
    slotMaxTime: '23:00:00',

    droppable: true,
    editable: true,
    eventResizableFromStart: true,

    slotDuration: '01:00:00',
    views: {
      timeGridWeek: {
        slotLabelFormat: {
          hour: 'numeric',
          minute: '2-digit',
          omitZeroMinute: false,
          meridiem: 'short',
          hour12: true,
        },
      },
      timeGridDay: {
        slotLabelFormat: {
          hour: 'numeric',
          minute: '2-digit',
          omitZeroMinute: false,
          meridiem: 'short',
          hour12: true,
        },
        eventContent(renderProps, createElement) {
          return createElement(
            'div',
            {
              style: {},
            },
            [
              createElement('p', {}, renderProps.timeText),
              createElement('p', {}, [
                createElement('b', {}, 'Personal: '),
                renderProps.event.title
              ]),
              createElement('p', {}, [
                createElement('b', {}, 'Presupuesto: '),
                renderProps.event.extendedProps['presupuesto'].nombre,
              ]),
              createElement('p', {}, renderProps.event.extendedProps['presupuesto'].descripcion),
            ]
          );
        },
      },
    },

    customButtons: {
      dia: {
        text: 'Dia',
        click: () => {
          this.apiDelCalendario.changeView('timeGridDay');
        },
      },
      sem: {
        text: 'Sem',
        click: () => {
          this.apiDelCalendario.changeView('timeGridWeek');
        },
      },
      mes: {
        text: 'Mes',
        click: () => {
          this.apiDelCalendario.changeView('dayGridMonth');
        },
      },
      ano: {
        text: 'Año',
        click: () => {
          this.apiDelCalendario.changeView('multiMonthYear');
        },
      },
    },

    headerToolbar: {
      left: 'title',
      center: 'dia,sem,mes,ano',
      right: 'today prev,next',
    },

    eventDrop: this.eventChanged.bind(this),

    // drop: function(info) {
    //   console.log('drop', info);
    //   // is the "remove after drop" checkbox checked?
    //   if (this.checkbox) {
    //     // if so, remove the element from the "Draggable Events" list
    //     info.draggedEl.parentNode.removeChild(info.draggedEl);
    //   }
    // },

    eventResize: this.eventChanged.bind(this),

    eventClick: this.eventClicked.bind(this),
  };

  eventos: EventSourceInput = null;

  theCheckbox: boolean = false;

  ngOnInit(): void {
    console.log(this.elCalendario);
    this.apiDelCalendario = this.elCalendario.getApi();
    console.log(this.eventosReales);
    this.calendarOptions.events = this.eventosReales;
    setTimeout(() => {
      this.apiDelCalendario = this.elCalendario.getApi();
      this.apiDelCalendario.resetOptions(this.calendarOptions);
      this.mostrarDia(this.apiDelCalendario.today);
      this.mostrarMes(this.apiDelCalendario.today);
    }, 100);

    /**
     * Esta línea de código se utiliza para inicializar la variable 'self'
     * con una referencia al componente actual.
     */
    // var self = this;

    // let draggableEl = document.getElementById('external-events');

    // new Draggable(draggableEl, {
    //   itemSelector: '.fc-event',
    //   eventData: function (eventEl: any) {
    //     console.log('eventEl => ', eventEl);
    //     // if (self.theCheckbox) {
    //     //   console.log('mover');
    //     //   eventEl.parentNode.removeChild(eventEl);
    //     // }
    //     return {
    //       title: eventEl.innerText,
    //     };
    //   },
    // });
  }

  hideDialog() {
    this.mostrarEvento = false;
  }

  verPresupuesto(idPresupuesto: number) {
    this.router.navigateByUrl(`/presupuestos/mostrar/${idPresupuesto}`);
  }

  eventClicked(info) {
    console.log('eventClick', info.event);
    this.eventoSeleccionado = info.event;
    this.mostrarEvento = true;
  }

  eventChanged(info) {
    const oldEvento = {
      id: parseInt(info.oldEvent.id),
      title: info.oldEvent.title,
      allDay: false,
      color: info.oldEvent.backgroundColor,
      start: this.fechaService.fechaAbsolutaToString(
        this.fechaService.fechaAbsoluta(info.oldEvent.startStr)
      ),
      end: this.fechaService.fechaAbsolutaToString(
        this.fechaService.fechaAbsoluta(info.oldEvent.endStr)
      ),
    };
    const newEvento: Reserva = {
      id: parseInt(info.event.id),
      inicio: this.fechaService.fechaAbsolutaToString(
        this.fechaService.fechaAbsoluta(info.event.startStr)
      ),
      fin: this.fechaService.fechaAbsolutaToString(
        this.fechaService.fechaAbsoluta(info.event.endStr)
      ),
    };
    console.log('evento cambiado => ', newEvento);
    console.log('evento anterior => ', oldEvento);

    this.loadingEventos.emit(true);
    this.calendarioService.cambiarReservaRRHH(newEvento).subscribe({
      next: (reserva) => {
        console.warn('Reserva actualizada correctamente.');
        this.loadingEventos.emit(false);
      },
      error: (error) => {
        // console.error(error);
        this.loadingEventos.emit(false);
        this.messageService.error(error.error.detail);
        info.revert();
      },
    });
  }

  showDate(event) {
    console.log(event);
    setTimeout(() => {
      const apiDelCalendario = this.elCalendario.getApi();
      apiDelCalendario.gotoDate(event.data.start);
    }, 100);
  }

  /**
   * Maneja el evento de clic en una fecha en el calendario.
   * @param arg - El objeto de evento de clic en fecha.
   */
  mostrarFecha(fecha) {
    console.log('date click! ' + fecha.dateStr);

    this.mostrarDia(fecha.dateStr);

    // setTimeout(() => {
    //   const apiDelCalendario = this.elCalendario.getApi();
    //   if (apiDelCalendario.view.type === 'dayGridWeek') {
    //     apiDelCalendario.gotoDate(fecha.dateStr);
    //   }
    //   if (apiDelCalendario.view.type === 'dayGridMonth') {
    //     apiDelCalendario.gotoDate(fecha.dateStr);
    //   }
    // }, 100);

    // this.eventosReales.push({ title: 'lisitaaaa', date: '2024-04-12' });

    // this.calendarOptions.weekends = !this.calendarOptions.weekends

    // if (this.eventos != this.eventosReales) {
    //   this.eventos = this.eventosReales;
    // } else {
    //   this.eventos = [];
    // }
  }

  /**
   * Muestra los detalles del evento.
   * @param event - El objeto de evento.
   */
  show() {
    console.log(event);
  }

  /**
   * Cambia la vista del calendario a una vista de lista para la fecha especificada.
   * @param fecha - La fecha para mostrar en la vista de lista.
   */
  mostrarDia(fecha) {
    setTimeout(() => {
      const apiDelCalendario = this.elCalendario.getApi();
      apiDelCalendario.changeView('timeGridDay', fecha);
    }, 100);
  }
  mostrarSemana(fecha) {
    setTimeout(() => {
      const apiDelCalendario = this.elCalendario.getApi();
      apiDelCalendario.changeView('timeGridWeek', fecha);
    }, 100);
  }
  mostrarMes(fecha) {
    setTimeout(() => {
      const apiDelCalendario = this.elCalendario.getApi();
      apiDelCalendario.changeView('dayGridMonth', fecha);
    }, 100);
  }
}
