<ng-container *ngIf="!presupuestoID">
  <div class="grid">
    <div class="col-12">
      <div class="card p-fluid">
        <h2>Gastos</h2>
        <hr />
        <p-table
          #dt
          [value]="gastos"
          [columns]="cols"
          [lazy]="true"
          (onLazyLoad)="loadCustomers($event)"
          [loading]="loading"
          [paginator]="true"
          [rows]="10"
          [rowsPerPageOptions]="[10, 20, 30]"
          [totalRecords]="totalRecords"
          [globalFilterFields]="['importe']"
          responsiveLayout="scroll"
          [showCurrentPageReport]="true"
          currentPageReportTemplate="Del {{ mostrarDel }} al {{
            mostrarAl
          }} para {{ totalRecords }} Registros"
          selectionMode="multiple"
          [rowHover]="true"
          dataKey="id"
        >
          <ng-template pTemplate="caption">
            <div
              class="flex flex-column md:flex-row md:justify-content-between md:align-items-center"
            >
              <!-- <button
                pButton
                pRipple
                icon="pi pi-plus"
                class="p-button-rounded p-button-success"
                [routerLink]="['nuevo']"
              ></button> -->

              <div
                class="flex flex-column md:flex-row md:justify-content-end md:align-items-center"
              >
                <button
                  pButton
                  label="Filtros"
                  class="mr-2 p-button-outlined my-2 md:my-0"
                  icon="pi pi-filter-slash"
                  (click)="clear(dt)"
                ></button>
                <p-dropdown
                  id="selctEstado"
                  [options]="selectFacturaSn"
                  (onChange)="dt.filter($event.value, 'facturaSn', 'contains')"
                  placeholder="Facturado"
                  [(ngModel)]="valorNulleable"
                  [showClear]="true"
                  class="mr-2 max-w-full"
                >
                </p-dropdown>
                <span class="mr-2 block mt-2 md:mt-0 p-input-icon-left">
                  <span class="p-input-icon-left ml-auto">
                    <i class="pi pi-search"></i>
                    <input
                      id="buscadorPresupuesto"
                      pInputText
                      input
                      type="text"
                      (input)="
                        dt.filter(
                          $any($event).target.value,
                          'presupuesto',
                          'contains'
                        )
                      "
                      placeholder="Presupuesto"
                      [(ngModel)]="valorNulleable2"
                    />
                  </span>
                </span>
                <span class="block mt-2 md:mt-0 p-input-icon-left">
                  <span class="p-input-icon-left ml-auto">
                    <i class="pi pi-search"></i>
                    <input
                      id="buscador"
                      pInputText
                      input
                      type="number"
                      (input)="
                        dt.filter(
                          $any($event).target.value,
                          'importe',
                          'contains'
                        )
                      "
                      placeholder="Importe"
                      class="max-w-max"
                      [(ngModel)]="valorNulleable3"
                    />
                  </span>
                </span>
              </div>
            </div>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th pSortableColumn="importe">
                Importe <p-sortIcon field="importe"></p-sortIcon>
              </th>
              <th pSortableColumn="presupuesto">
                Presupuesto <p-sortIcon field="presupuesto"></p-sortIcon>
              </th>
              <th pSortableColumn="facturaSn">
                FacturaSn <p-sortIcon field="facturaSn"></p-sortIcon>
              </th>
              <th>Generar Factura</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-gasto>
            <tr>
              <td>
                <span class="p-column-title">importe</span>
                {{
                  gasto.importe
                    ? (gasto.importe | currency : "EUR" : "symbol")
                    : "N/A"
                }}
              </td>
              <td>
                <span class="p-column-title">presupuesto</span>
                {{ gasto.presupuesto ? gasto.presupuesto.nombre : "N/A" }}
              </td>
              <td>
                <span class="p-column-title">facturaSn</span>
                {{ gasto.facturaSn ? gasto.facturaSn : "N/A" }}
              </td>
              <td>
                <p-button
                  class="m-2"
                  icon="pi pi-file-edit"
                  styleClass="p-button-warning p-button-rounded"
                  (onClick)="navegarFacturarGasto(gasto)"
                  *ngIf="gasto.facturaSn == 'No'"
                ></p-button>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td>*No hay Gastos registrados*</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="presupuestoID">
  <p-panel
    class="grid my-1 mx-2"
    [style]="{ width: '100%' }"
    [toggleable]="true"
    [collapsed]="!!presupuestoID"
  >
    <ng-template pTemplate="header">
      <span class="font-semibold text-2xl">Gastos</span></ng-template
    >
    <div class="col-12">
      <div class="grid">
        <div class="col-12 grid pt-0">
          <div class="col-12 py-0">
            <div class="card px-4 py-4 no-borders">
              <p-table
                #dt
                [value]="gastos"
                [columns]="cols"
                [lazy]="true"
                [loading]="loading"
                responsiveLayout="scroll"
                [showCurrentPageReport]="true"
                selectionMode="multiple"
                [rowHover]="true"
                dataKey="id"
              >
                <ng-template pTemplate="caption">
                  <div
                    class="flex flex-column md:flex-row md:justify-content-between md:align-items-center"
                  >
                    <!-- <button
                      pButton
                      pRipple
                      icon="pi pi-plus"
                      class="p-button-rounded p-button-success"
                      [routerLink]="['nuevo']"
                    ></button> -->
                  </div>
                </ng-template>
                <ng-template pTemplate="header">
                  <tr>
                    <th>Importe</th>
                    <th>FacturaSn</th>
                    <th>Generar Factura</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-gasto>
                  <tr>
                    <td>
                      <span class="p-column-title">importe</span>
                      {{
                        gasto.importe
                          ? (gasto.importe | currency : "EUR" : "symbol")
                          : "N/A"
                      }}
                    </td>
                    <td>
                      <span class="p-column-title">facturaSn</span>
                      {{ gasto.facturaSn ? gasto.facturaSn : "N/A" }}
                    </td>
                    <td>
                      <p-button
                        class="m-2"
                        icon="pi pi-file-edit"
                        styleClass="p-button-warning p-button-rounded"
                        (onClick)="navegarFacturarGasto(gasto)"
                        *ngIf="gasto.facturaSn == 'No'"
                      ></p-button>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                  <tr>
                    <td>*No hay Gastos registrados*</td>
                  </tr>
                </ng-template>
              </p-table>
              <div class="my-2 mx-0 flex justify-content-end">
                <p class="inline mr-3 ml-0 max-w-max">
                  <b
                    >SUB TOTAL GASTOS:
                    {{
                      totalGastos
                        ? (totalGastos | currency : "EUR" : "symbol")
                        : "0 €"
                    }}
                  </b>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </p-panel>
</ng-container>
