import { OnInit, inject } from '@angular/core';
import { Component } from '@angular/core';
import { LayoutService } from '../../service/app.layout.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html',
})
export class AppMenuComponent implements OnInit {
  model: any[] = [];

  constructor(public layoutService: LayoutService) {}

  private readonly router = inject(Router)

  usuario: any = JSON.parse(localStorage.getItem('usuario'));

  url: string = null;

  ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event) => {
      //  console.log('The URL changed to: ' + event['url'])
       this.url = event['url'];
    });
    // console.log(this.usuario);
    this.model = [
      {
        label: `Bienvenido ${this.usuario.nombre} ${this.usuario.apellido1}`,
        items: [
          {
            label: 'Inicio',
            icon: 'pi pi-fw pi-home',
            routerLink: ['/'],
          },
          {
            label: 'Presupuestos',
            icon: 'pi pi-fw pi-file-edit',
            routerLink: [`/presupuestos`],
          },
          
          {
            label: 'Calendarios',
            icon: 'pi pi-calendar',
            items: [
              {
                label: 'Calendario de Personal',
                icon: 'pi pi-fw pi-users',
                routerLink: [`/calendario-personal`],
              },
              {
                label: 'Calendario de Recursos',
                icon: 'pi pi-fw pi-th-large',
                routerLink: [`/calendario-recursos`],
              },
              {
                label: 'Calendario de Presupuestos',
                icon: 'pi pi-fw pi-file-edit',
                routerLink: [`/calendario-presupuestos`],
              },
            ]
          },
          {
            label: `Administracion`,
            icon: 'pi pi-user',
            items: [
              {
                label: 'Clientes',
                icon: 'pi pi-fw pi-users',
                routerLink: [`/clientes`],
              },
              {
                label: 'Proveedores',
                icon: 'pi pi-fw pi-users',
                routerLink: [`/proveedores`],
              },
              // {
              //   label: 'Proyectos',
              //   icon: 'pi pi-fw pi-calendar',
              //   routerLink: [`/proyectos`],
              // },
              {
                label: 'Pendiente por Comprar',
                icon: 'pi pi-fw pi-cart-plus',
                routerLink: [`/ordenes-compras/pendiente-comprar`],
                command: () => {
                  sessionStorage.setItem('lastUrl', this.url)
                }
              },
              {
                label: 'Ordenes Compras',
                icon: 'pi pi-fw pi-truck',
                routerLink: [`/ordenes-compras`],
              },
              {
                label: 'Personas',
                icon: 'pi pi-fw pi-user',
                routerLink: [`/personas`],
              },
            ],
          },
          {
            label: 'Tareas',
            icon: 'pi pi-list',
            items: [
              {
                label: 'Tareas',
                icon: 'pi pi-fw pi-list',
                routerLink: [`/tareas/lineas-tareas`],
              },
              {
                label: 'Grupos de Tareas',
                icon: 'pi pi-fw pi-inbox',
                routerLink: [`/tareas`],
              },
              {
                label: 'Ordenes de Trabajo',
                icon: 'pi pi-fw pi-briefcase',
                routerLink: [`/ordenes-trabajo`],
              },
              {
                label: 'Board Tareas por Estado',
                icon: 'pi pi-fw pi-th-large',
                routerLink: [`/board-tareas-estado`],
              }
            ]
          },
          {
            label: 'Productos',
            icon: 'pi pi-shopping-bag',
            items: [
              {
                label: 'Productos',
                icon: 'pi pi-fw pi-shopping-bag',
                routerLink: [`/productos`],
              },
              {
                label: 'Nuevo Producto',
                icon: 'pi pi-fw pi-shopping-bag',
                routerLink: [`/productos/scanner`],
              },
              {
                label: 'Nuevo Producto Propio',
                icon: 'pi pi-fw pi-shopping-bag',
                routerLink: [`/productos/nuevo-propio`],
                command: () => {
                  sessionStorage.setItem('lastUrl', this.url)

                }
              },
              {
                label: 'Grupos de Productos',
                icon: 'pi pi-fw pi-cart-plus',
                routerLink: [`/grupos-productos`],
              },
            ]
          },
          {
            label: 'Facturas',
            icon: 'pi pi-file',
            items: [
              {
                label: 'Facturas Emitidas',
                icon: 'pi pi-fw pi-file-export',
                routerLink: [`/facturas-emitidas`],
              },
              {
                label: 'Facturas Recibidas',
                icon: 'pi pi-fw pi-file-import',
                routerLink: [`/facturas-recibidas`],
              },
              {
                label: 'Gastos',
                icon: 'pi pi-fw pi-file-import',
                routerLink: [`/gastos`],
              },
            ]
          },
          {
            label: `Almacen`,
            icon: 'pi pi-building',
            items: [
              {
                label: 'Ingresar Stock',
                icon: 'pi pi-fw pi-shopping-cart',
                routerLink: [`/productos/actualizar-stock`],
                command: () => {
                  sessionStorage.setItem('lastUrl', this.url)
                }
              },
              {
                label: 'Imprimir Codigo de Barras',
                icon: 'pi pi-fw pi-bars',
                routerLink: [`/productos/print-barcode`],
              },
              {
                label: 'Ubicaciones',
                icon: 'pi pi-fw pi-map-marker',
                routerLink: [`/ubicaciones`],
              },
              {
                label: 'Etiquetas Ubicaciones',
                icon: 'pi pi-fw pi-ticket',
                routerLink: [`ubicaciones/etiquetas-ubicaciones`],
              },
              {
                label: 'Almacenes',
                icon: 'pi pi-fw pi-inbox',
                routerLink: [`/almacenes`],
              },
            ],
          },
          {
            label: `Mercancias`,
            icon: 'pi pi-database',
            items: [
              {
                label: 'Ver Mercancias',
                icon: 'pi pi-fw pi-list',
                routerLink: [`/mercancias`],
              },
              {
                label: 'Ver Recursos',
                icon: 'pi pi-fw pi-th-large',
                routerLink: [`/productos/recursos`],
              },
              {
                label: 'Ver RRHH',
                icon: 'pi pi-fw pi-users',
                // routerLink: [`/productos/recursos`],
              },
              {
                label: 'Mover',
                icon: 'pi pi-fw pi-arrow-right-arrow-left',
                // routerLink: [`/mover`],
                command: () => {
                  sessionStorage.setItem('lastUrl', this.url)
                  this.router.navigateByUrl('/mover');
                }
              },
            ],
          },
          {
            label: `Configuracion`,
            icon: 'pi pi-cog',
            items: [
              // {
              //   label: 'Operaciones Internas',
              //   icon: 'pi pi-fw pi-truck',
              //   routerLink: [`/operaciones-internas`],
              // },
              {
                label: 'Procesos',
                icon: 'pi pi-fw pi-code',
                routerLink: [`/procesos`],
              },
              {
                label: 'Empresa',
                icon: 'pi pi-fw pi-building',
                routerLink: [`/empresa`],
                command: () => {
                  sessionStorage.setItem('lastUrl', this.url)
                }
              },
            ],
          },
        ],
      },
    ];
  }
}
