import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { image } from 'html2canvas/dist/types/css/types/image';
import { Subscription } from 'rxjs';
import { CustomMessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-ver-imagen',
  templateUrl: './ver-imagen.component.html',
  styleUrls: ['./ver-imagen.component.scss']
})
export class VerImagenComponent implements OnInit, OnDestroy {

  private readonly router = inject(Router)
  private readonly messageService = inject(CustomMessageService)

  public ordenID;

  public imagen: string;

  public subs: Subscription[] = []

  ngOnInit(): void {
    this.imagen = sessionStorage.getItem('imagen')
    
    if ( this.imagen == 'null' || this.imagen == null || this.imagen == undefined) {
      this.messageService.error('imagen perdida, vuelva a la orden de compra')
      this.back()
    }
  }

  back() {
    const lastUrl = sessionStorage.getItem('lastUrl')
    if (lastUrl && lastUrl != 'null') {
      if (lastUrl === '/imagen') {
        this.router.navigateByUrl('/');
      } else {
        this.router.navigateByUrl(lastUrl);
      }
    } else {
      this.router.navigateByUrl('/');
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach(
      sub => sub.unsubscribe()
    )
  }
}
