import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { FormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CustomMessageService } from 'src/app/services/message.service';
import { ClientesService } from 'src/app/services/clientes.service';
import { FacturaService } from 'src/app/services/factura.service';
import { Location } from '@angular/common';
import { EnumService } from 'src/app/services/enum.service';
import { PresupuestoService } from 'src/app/services/presupuesto.service';

@Component({
  selector: 'app-gastos-factura',
  templateUrl: './gastos-factura.component.html',
  styleUrls: ['./gastos-factura.component.scss'],
})
export class GastosFacturaComponent implements OnInit, OnDestroy {
  // servicios requeridos
  private readonly presupuestoService = inject(PresupuestoService);
  private readonly router = inject(Router);
  private readonly messageService = inject(CustomMessageService);
  private readonly Form = inject(FormBuilder);
  private readonly activatedRoute = inject(ActivatedRoute);

  public compuestoSnOptions: Object[] = [
    { value: 'Si', label: 'Si' },
    { value: 'No', label: 'No' },
  ];

  public loadingProveedores = true;

  public hadSubmit: boolean = false;

  public loading = false;

  public subs: Subscription[] = [];

  public imagen: string = null;

  public newGastoFactura = this.Form.group({
    id: [null, Validators.required],
    numeroFactura: [null, Validators.required],
    iva: [null],
    nif: [null, Validators.required],
  });

  // form getters ----------------------------------------------------------------------------------

  get id() {
    return this.newGastoFactura.get('id') as UntypedFormControl;
  }
  get numeroFactura() {
    return this.newGastoFactura.get('numeroFactura') as UntypedFormControl;
  }
  get iva() {
    return this.newGastoFactura.get('iva') as UntypedFormControl;
  }
  get nif() {
    return this.newGastoFactura.get('nif') as UntypedFormControl;
  }

  // end form getter --------------------------------------------------------------------------------

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next: params => {
        this.id.setValue(params['idGasto'])
        this.cargarGasto()
      }
    })
  }

  back() {
    const lastUrl = sessionStorage.getItem('lastUrl')
    if (lastUrl && lastUrl != 'null') {
      if (lastUrl === '/gastos/gastos-factura/' + this.id.value) {
        this.router.navigateByUrl('/');
      } else {
        this.router.navigateByUrl(lastUrl);
      }
    } else {
      this.router.navigateByUrl('/');
    }
  }

  validar(campo: string) {
    return this.newGastoFactura.get(campo)?.valid;
  }

  // dinamica de los componentes -------------------------------------------------------------------

  submit() {
    this.hadSubmit = true;

    this.loading = true;

    if (this.newGastoFactura.invalid) {
      console.log(this.newGastoFactura.invalid);

      this.loading = false;
      this.messageService.error('Verifique los campos');
      return;
    }

    let GastoFacturaToSend = this.newGastoFactura.value;

    this.reqCreateGastoFactura(GastoFacturaToSend);
  }

  cargarGasto() {
    this.loading = true;
    this.subs.push(
      this.presupuestoService.getGasto(this.id.value).subscribe({
        next: (res) => {
          this.loading = false;
          this.imagen = res.imagen as string;
        },
        error: (error) => {
          console.error(error);
          this.messageService.error(error.error.detail);
        },
      })
    );

  }

  // llamadas al back -----------------------------------------------------------------------------

  reqCreateGastoFactura(GastoFacturaToSend) {
    this.subs.push(
      this.presupuestoService.createGastoFactura(GastoFacturaToSend).subscribe(
        (response) => {
          console.log(response);
          this.messageService.add({
            severity: 'success',
            summary: 'Exito!',
            detail: 'Facturacion de gasto exitosa!',
          });
          console.log(response);
          this.back()
        },
        (error) => {
          console.error(error);

          this.loading = false;
          this.messageService.error(error.error.detail);
          return;
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach((subscripcion) => {
      subscripcion.unsubscribe();
    });
  }
}
